import React from 'react';
import PostsPreview from '@components/PostsPreview/PostsPreview';
import PageLayout from '@containers/PageLayout';

const BlogPage = () => (
  <PageLayout title="Blog">
    <PostsPreview isBlog />
  </PageLayout>
);

export default BlogPage;
